export default {
	data() {
		return {
			moodQuestions: [
				{
					question: this.$t("mood.anxiety"),
					model: "anxiety",
					answers: [
						{ title: this.$t("answer.no"), value: 0 },
						{ title: this.$t("answer.small"), value: 1 },
						{ title: this.$t("answer.medium"), value: 2 },
						{ title: this.$t("answer.strong"), value: 3 },
					],
					error: "anxiety"
				},
				{
					question: this.$t("mood.aggression"),
					model: "aggression",
					answers: [
						{ title: this.$t("answer.no"), value: 0 },
						{ title: this.$t("answer.small"), value: 1 },
						{ title: this.$t("answer.medium"), value: 2 },
						{ title: this.$t("answer.strong"), value: 3 },
					],
					error: "aggression",
				},
				{
					question: this.$t("mood.libido"),
					model: "libido",
					answers: [
						{ title: this.$t("answer.no"), value: 0 },
						{ title: this.$t("answer.small"), value: 1 },
						{ title: this.$t("answer.medium"), value: 2 },
						{ title: this.$t("answer.strong"), value: 3 },
					],
					error: 'libido'
				},
				{
					question: this.$t("mood.sensitivity"),
					model: "sensitivity",
					answers: [
						{ title: this.$t("answer.no"), value: 0 },
						{ title: this.$t("answer.small"), value: 1 },
						{ title: this.$t("answer.medium"), value: 2 },
						{ title: this.$t("answer.strong"), value: 3 },
					],
					error: 'sensitivity'
				},
				{
					question: this.$t("mood.appetite"),
					model: "appetite",
					answers: [
						{ title: this.$t("answer.no"), value: 0 },
						{ title: this.$t("answer.small"), value: 1 },
						{ title: this.$t("answer.ordinary"), value: 2 },
						{ title: this.$t("answer.strong"), value: 3 },
					],
					error: "appetite"
				},
				{
					question: this.$t("mood.menstruation"),
					model: "menstruation",
					answers: [
						{ title: this.$t("common.yes"), value: 1 },
						{ title: this.$t("common.no"), value: 0 },
					],
				},
				{
					question: this.$t("history.text_8"),
					model: "pleasure",
					answers: [
						{ title: this.$t("answer.no"), value: 0 },
						{ title: this.$t("answer.small"), value: 1 },
						{ title: this.$t("answer.medium"), value: 2 },
						{ title: this.$t("answer.strong"), value: 3 },
					],
				},
				{
					question: this.$t("mood.activity"),
					model: "activity",
					answers: [
						{ title: this.$t("answer.no"), value: 0 },
						{ title: this.$t("answer.small"), value: 1 },
						{ title: this.$t("answer.medium"), value: 2 },
						{ title: this.$t("answer.strong"), value: 3 },
					],
					error: 'activity'
				},
				{
					question: this.$t("mood.meds"),
					model: "meds",
					answers: [
						{ title: this.$t("common.yes"), value: 1 },
						{ title: this.$t("common.no"), value: 0 },
					],
					error: "meds"
				},
				{
					question: this.$t("mood.pav"),
					model: "pav",
					answers: [
						{ title: this.$t("common.yes"), value: 1 },
						{ title: this.$t("common.no"), value: 0 },
					],
					error: 'pav'
				},
			],
		}
	}
}