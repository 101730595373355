<template>
    <div class="mood-picker" :class="[Object.keys(mood).length !== 0 ? 'mood-picked' : null]">
        <h3 class="h3">{{ $t('mood.general_state') }}</h3>

        <VueSlickCarousel
            v-if="Object.keys(mood).length == 0 && showSlickSlide > 329"
            v-bind="slickSettings"
            ref="slick">
            <div
                v-for="(item, index) in moods"
                :key="index"
                class="slider-mood-item"
                @click="pickMood(item)">
                <div class="icon-holder">
                    <img :src="require(`@/assets/images/${item.icon}`)" alt="..." />
                </div>
            </div>
        </VueSlickCarousel>

        <ul v-if="Object.keys(mood).length !== 0 || showSlickSlide < 330" class="choose-mood">
            <li
                v-for="(item, index) in moods"
                :key="index"
                class="mood-item"
                @click="pickMood(item)">
                <div class="icon-holder">
                    <img :src="require(`@/assets/images/${item.icon}`)" alt="..." />
                </div>
            </li>
        </ul>

        <div v-if="Object.keys(mood).length == 0" class="text-holder">
            <p>{{ $t('mood.empty') }}</p>
        </div>
        <div v-if="Object.keys(mood).length > 0" class="choosed-mood">
            <div class="row">
                <div class="col">
                    <span class="mood-title" :style="{ color: mood.color }">{{ mood.title }}</span>
                    <div class="text-holder">
                        <p>{{ mood.description }}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="icon-holder" @animationend="animation = false">
                        <img :src="require(`@/assets/images/${mood.icon}`)" alt="..." />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            isShowSlickSlide: true,
            slickSettings: {
                infinite: false,
                speed: 500,
                slidesToShow: 6,
                slidesToScroll: 3,
                centerPadding: '20px',

                responsive: [
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 5,
                        },
                    },
                    {
                        breakpoint: 300,
                        settings: {
                            slidesToShow: 5,
                        },
                    },
                ],
            },
            selectedMood: null,
            mood: {},
            animation: false,
            moods: [
                {
                    title: this.$t('mood.picker.item_1.title'),
                    value: 1,
                    icon: 'icon-md-mood-01.svg',
                    description: this.$t('mood.picker.item_1.desc'),
                    color: '#615EFE',
                },
                {
                    title: this.$t('mood.picker.item_2.title'),
                    value: 2,
                    icon: 'icon-md-mood-02.svg',
                    description: this.$t('mood.picker.item_2.desc'),
                    color: '#896CFE',
                },
                {
                    title: this.$t('mood.picker.item_3.title'),
                    value: 3,
                    icon: 'icon-md-mood-03.svg',
                    description: this.$t('mood.picker.item_3.desc'),
                    color: '#5FAEF8',
                },
                {
                    title: this.$t('mood.picker.item_4.title'),
                    value: 4,
                    icon: 'icon-md-mood-04.svg',
                    description: this.$t('mood.picker.item_4.desc'),
                    color: '#96DD94',
                },
                {
                    title: this.$t('mood.picker.item_5.title'),
                    value: 5,
                    icon: 'icon-md-mood-05.svg',
                    description: this.$t('mood.picker.item_5.desc'),
                    color: '#F8B147',
                },
                {
                    title: this.$t('mood.picker.item_6.title'),
                    value: 6,
                    icon: 'icon-md-mood-06.svg',
                    description: this.$t('mood.picker.item_6.desc'),
                    color: '#FB976C',
                },
                {
                    title: this.$t('mood.picker.item_7.title'),
                    value: 7,
                    icon: 'icon-md-mood-07.svg',
                    description: this.$t('mood.picker.item_7.desc'),
                    color: '#F36464',
                },
            ],
        };
    },
    methods: {
        pickMood(mood) {
            this.mood = null;
            this.mood = mood;
            this.$emit('input', this.mood);

            this.animation = true;
        },
        searchMoodByPropValue() {
            return this.moods.forEach((el) => {
                if (el.value === this.selectedMood) {
                    return (this.mood = el);
                }
            });
        },
    },
    watch: {
        selectedMood() {
            this.searchMoodByPropValue();
        },
    },
    computed: {
        showSlickSlide() {
            return window.innerWidth;
        },
    },
};
</script>

<style lang="scss" scoped>
.mood-picker {
    display: flex;
    flex-direction: column;

    .choose-mood {
        display: flex;
        margin-bottom: 12px;

        @media (min-width: 330px) {
            overflow: auto;
        }

        @media (max-width: 330px) {
            flex-wrap: wrap;
        }

        .mood-item {
            &:not(:last-child) {
                margin-right: 19px;
            }
        }

        .icon-holder {
            display: flex;
            width: 63px;
            height: 63px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .text-holder {
        font-weight: 500;
        font-size: 12px;
        line-height: 1.25;
        color: #897a6c;

        p {
            margin-bottom: 0;
        }
    }

    .choosed-mood {
        .row {
            display: flex;
            justify-content: space-between;
        }

        .mood-title {
            display: block;
            font-weight: 600;
            font-size: 24px;
            line-height: 1.3;
        }

        .text-holder {
            max-width: 150px;
            padding-left: 3px;
        }

        .icon-holder {
            width: 132px;
            height: 148px;

            @media (max-width: 350px) {
                width: 110px;
            }

            @media (max-width: 330px) {
                width: 80px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &.mood-picked {
        .choose-mood {
            .mood-item {
                &:not(:last-child) {
                    margin-right: 14.55px;

                    @media (max-width: 360px) {
                        margin-bottom: 10px;
                        margin-right: 10px;
                    }
                }
            }

            .icon-holder {
                width: 32px;
                height: 32px;
            }
        }
    }

    &.animated {
        .choosed-mood {
            .mood-title {
                animation: title-anim 0.7s;
                animation-fill-mode: both;
                animation-delay: 0.1s;
            }

            .text-holder {
                animation: text-anim 0.7s;
                animation-fill-mode: both;
                animation-delay: 0.2s;
            }

            .icon-holder {
                animation: icon-anim 0.5s;
                animation-fill-mode: both;
                animation-delay: 0.3s;
            }
        }
    }

    @keyframes title-anim {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes text-anim {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes icon-anim {
        0% {
            scale: 0;
        }
        100% {
            scale: 1;
        }
    }

    @keyframes icon-size-change {
        0% {
            width: 63px;
            height: 63px;
        }
        100% {
            width: 32px;
            height: 32px;
        }
    }
}

.slick-slider {
    margin-bottom: 19px;
}

.slider-mood-item {
    .icon-holder {
        width: 63px;
        height: 63px;
        cursor: pointer;

        @media (max-width: 460px) {
            width: 53px;
            height: 53px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@media (max-width: 350px) {
    .mood-picker .choosed-mood .row .col {
        &:nth-child(1) {
            max-width: 150px;
        }
    }
}
</style>

<style lang="scss">
.mood-picker {
    .slick-prev,
    .slick-next {
        width: 50px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;

        &::before {
            content: '';
            display: block;
            opacity: 1;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
        }

        &.slick-disabled {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    .slick-prev {
        padding-right: 23px;
        left: 0;
        background-image: linear-gradient(to left, rgba(255, 0, 0, 0) 0%, rgb(255 255 255) 35%);

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.62305 1.09216L1.62305 7.09216L7.62305 13.0922' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
    }

    .slick-next {
        padding-left: 23px;
        right: 0;
        background-image: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgb(255 255 255) 35%);

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.77051 13.954L7.77051 7.95398L1.77051 1.95398' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
    }
}
</style>
